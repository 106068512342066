// Translated
// Migrated
<template lang="pug">
component(:is="elementIs", :style="imageStyle", :class="{'__background-image': true, '--choke-height': dontExpandY}", :data-loaded="loaded")
  slot
</template>

<script>
import { mapState } from 'pinia'
import screenWidthMixin from '@packages/screen/mixins/screen-width.mixin.js'

export default defineNuxtComponent({
  setup () {
    const { public: { images: imagesConfig } } = useRuntimeConfig()

    return {
      imagesConfig
    }
  },

  mixins: [screenWidthMixin],

  props: {
    // Hero or Icon
    fallbackType: {
      type: String,
      default: 'hero'
    },

    // Cloudinary or Url
    type: {
      type: String,
      default: 'cloudinary'
    },

    image: {
      type: String,
      default: ''
    },

    align: {
      type: String,
      default: ''
    },

    height: {
      type: [Number, String],
      default: 0
    },

    sizes: {
      type: Array,
      default: () => []
    },

    elementIs: {
      type: String,
      default: 'div'
    },

    dontExpandY: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      src: '',
      imageId: '',
      loaded: false
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['isCharter']),

    fallbackId () {
      return this.fallbackType === 'hero' ? this.imagesConfig.fallbackHeroId : this.imagesConfig.fallbackImage
    },

    isCloudinary () {
      if (!this.image || new RegExp('^(http|https)://').test(this.image)) {
        return false
      }
      return this.type === 'cloudinary'
    },

    sizeList () {
      return (this.sizes || []).length > 0
        ? this.sizes
        : [
          {
            maxWidth: null,
            size: 1400 * 2
          },
          {
            maxWidth: 2099,
            size: 2100 * 1.5
          },
          {
            maxWidth: 1599,
            size: 1600 * 1.5
          },
          {
            maxWidth: 1279,
            size: 1280 * 1.5
          },
          // { maxWidth: 1079, size: 1080 * 1.5 },
          {
            maxWidth: 920,
            size: 920 * 1.5
          },
          {
            maxWidth: 768,
            size: 768 * 1.5
          },
          {
            maxWidth: 500,
            size: 500 * 1.5
          },
          {
            maxWidth: 30,
            size: 30
          }
        ]
    },

    sizeData () {
      return this.getImageData(this.imageId, this.sizeList)
    },

    imageStyle () {
      const styles = [
        `background-position: ${this.align ? this.align : 'center'}`
      ]
      if (this.src) {
        styles.push(`background-image: url(${this.src})`)
      }
      if (this.height) {
        styles.push(`height: ${this.height}px`)
      }

      return styles.join(';')
    }
  },

  watch: {
    image (newImage) {
      this.init(newImage)
    }
  },

  created () {
    this.init()
  },

  methods: {
    init (img = null) {
      if (!this.isCloudinary) {
        this.setup()
        this.setResizeListener(this.setup)
        return
      }

      const initId = img || this.image || this.imageId
      if (!process?.browser && initId) {
        this.src = formatCloudinaryImagePath(initId, {
          transformation: { custom: 'h_30,c_scale,e_blur:100' },
          isCharter: this.isCharter
        })
      }

      // try the id with a small image.
      // if it fails, load the fallback
      // first in small size, then higher and higher resolution
      preloadImage(formatCloudinaryImagePath(img || this.image, {
          transformation: { custom: 'h_30,c_scale,e_blur:100' },
          isCharter: this.isCharter
        }))
        .then(() => (this.imageId = this.image))
        .catch(() => (this.imageId = this.fallbackId))
        .finally(() => {
          this.src = formatCloudinaryImagePath(this.imageId, {
          transformation: { custom: 'h_30,c_scale,e_blur:100' },
          isCharter: this.isCharter
        })
          this.setup()
          this.setResizeListener(this.setup)
        })
    },

    setup (width) {
      if (!this.isCloudinary) {
        this.src = this.image
        return
      }

      const w = width || this.screenWidth
      if (this.sizeData.entries.length > 0 && w > 0) {
        // get the closest, larger, size from the current screen width
        const entries = this.sizeData.entries.filter(([width]) => width > w)
        const entrySrc = entries.length > 0 ? entries[0][1] : this.sizeData.entries[this.sizeData.entries.length - 1][1]

        if (entrySrc && entrySrc !== this.src) {
          // with cloudinary, we count on the image doesn't exist if it fails
          preloadImage(entrySrc)
            .then(() => (this.src = entrySrc))
            .then(() => (this.loaded = true))
            .catch(() => (this.imageId = this.fallbackId))
        }
      }
    },

    /**
     * Takes a imageId and an array of { maxWidth: number|null, size: number, custom?: string }[]
     * And returns an object with { srcset, src, sizes }
     * @param imageId string
     * @param sizeList Array({ maxWidth: number|null, size: number })
     * @returns {[{size: number, maxWidth: null},{size: number, maxWidth: number},{size: number, maxWidth: number}]|*}
     */
    getImageData (imageId, sizeList) {
      const transformKey = '{TRANSFORM}'
      const transformablePath = formatCloudinaryImagePath(imageId, {
        transformation: { custom: transformKey },
        isCharter: this.isCharter
      })
      const getWidthSrc = (w, custom) => transformablePath.replace(transformKey, `w_${w},c_limit${custom ? ',' + custom : ''}`)

      const sortedSizes = (sizeList || [])
        .sort((a, b) => a.size - b.size)
      const entries = sortedSizes
        .map(({ size, custom }) => [size, getWidthSrc(size, custom), transformablePath.replace(transformKey, custom)])
      const srcset = entries
        .map(([size, src]) => `${src} ${size}w`)
        .join(',\n')
      const sizes = sortedSizes
        .map(({ maxWidth, size }) => maxWidth ? `(max-width: ${maxWidth}px) ${size}px` : `${size}px`)
        .join(', ')

      return {
        src: getWidthSrc(sortedSizes?.[0]?.size || 768, sortedSizes?.[0]?.custom),
        transformablePath,
        srcset,
        entries,
        sizes
      }
    },
  }
})
</script>

<style lang="scss" scoped>
.__background-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;

  &:not(.--choke-height) {
    height: 100%;
  }
}
</style>
